import config from "./config";
import { reactLocalStorage } from "reactjs-localstorage";

const getTotalDuration = (storageType) => {
    let totalDuraction
    if(storageType === "local"){
      totalDuraction= new Date().getTime() - localStorage.getItem("localStartTime")
    }else if(storageType === "session"){
      totalDuraction= new Date().getTime() - sessionStorage.getItem("sessionStartTime")
    }
    return totalDuraction
  };

async function sendGuiDataEvent(data) {
  try{
  if((new Date().getTime() - sessionStorage.getItem("lastPublish")) > 300000){  //5mins
    sessionStorage.setItem("sessionStartTime", new Date().getTime()) 
}
  if (!localStorage.getItem("randomNumber")) {
    //generate 5 digit random no
    const min = 10000;
    const max = 99999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log("----- random number ----->", randomNumber);
    localStorage.setItem("randomNumber", randomNumber);
  }
  //----> session id generate logic
  var sessionTime = localStorage.getItem("sessionTime")
    ? localStorage.getItem("sessionTime")
    : "";
  var sessionId = localStorage.getItem("sessionId")
    ? localStorage.getItem("sessionId")
    : "";
  if (sessionTime && sessionId && Date.now() - sessionTime > 300000) {
    // 5 min
    console.log(
      "----- session id updated : --->" +
        Date.now() +
        "" +
        localStorage.getItem("randomNumber")
    );
    const a = Date.now() + "" + localStorage.getItem("randomNumber");
    localStorage.setItem("sessionId", a);
    localStorage.setItem("user_type", "repeatedUser"); // repeated user
  }
  sessionTime = Date.now();
  if (sessionId === "") {
    //set session id for first time only
    sessionId = sessionTime + "" + localStorage.getItem("randomNumber");
    localStorage.setItem("sessionId", sessionId);
  }
  localStorage.setItem("sessionTime", sessionTime);  
 // ----> generate device id
var deviceId = localStorage.getItem("device_id")
? localStorage.getItem("device_id")
: "";
if (deviceId === "") {
// milli seconds till generate
deviceId = Date.now() + "" + localStorage.getItem("randomNumber");
console.log("----- device id set : --->" + deviceId);
localStorage.setItem("device_id", deviceId);
}

// ----> msisdn calc 
const guiDataEvent = {
    operator_id: "dana_indonesia_livexgames",
    domain: window.location.hostname,
    user_agent: navigator.userAgent,
    uid: reactLocalStorage.get("uid")
      ? reactLocalStorage.get("uid")
      :'NA' ,
    msisdn: reactLocalStorage.get("msisdn")
      ? reactLocalStorage.get("msisdn")
      :'NA' ,
    device_type: "web",
    previouspage: "NA",
    session_id:localStorage.getItem('sessionId'),
    channel:localStorage.getItem('channel')?localStorage.getItem('channel'):"wap",
    trace: "",
    language:reactLocalStorage.get("lang") || 'id',
    request_time: new Date().toISOString(),
    aon_time: getTotalDuration("local"),
    session_time_duration: getTotalDuration("session"),
    session_start_time: sessionStorage.getItem("sessionStartTime"),
    referrer_info: "NA",
    request_time: new Date().toISOString(),
  };
  
  let tracer = reactLocalStorage.get("tracer")
  ? JSON.parse(reactLocalStorage.get("tracer"))
  : "";
guiDataEvent["previouspage"] = tracer[tracer.length - 1];
guiDataEvent["sub_event"] = data["page"] + "_" + data["event"];
tracer = tracer + data["page"] + "_" + data["event"] + "|";
reactLocalStorage.set("tracer", JSON.stringify(tracer));
guiDataEvent["trace"] = tracer;
  let objData = { ...guiDataEvent, ...data };
  var jsonData_base64 = window.btoa(JSON.stringify(objData)); //base_64
  let url = config.get("elastic_base_url");
    const response = await fetch(url, {
      method: "POST",
      headers: { "guiTopic": "livexgames_services" },
      body: jsonData_base64,
    });

    if (response.ok) {
      console.log("the data is:",data)
       console.log("resp>>>",response)
      return true;
    } else {
      console.log("API request failed with status:", response.status);
      return false;
    }
  } catch (error) {
    console.log("Error sending event:", error);
    return false;
  }
}

export function SendGuiDataEvents(data) {
  sendGuiDataEvent(data).then((success) => {
    if (success) {
      console.log("The event is send")
    } else {
      console.log("The event is not send")
      
    }
  });
}
