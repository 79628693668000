import React,{useEffect} from "react"
import success from "../assets/icons/tick_icon.png"
import error from "../assets/icons/error_icon.png"
import lowBal from "../assets/icons/low_balance_icon1.png"
import './SubscriptionModal.scss'
import { SubscriptionModalText as txt } from "../Text"
import { useLanguage } from "../Context/LangContext"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { reactLocalStorage } from "reactjs-localstorage"
function SubscriptionModal() {
    const {successModal,lowBalModal,errorModal}=txt;
    const {language}=useLanguage();
    const history=useHistory();
    const status=reactLocalStorage.get("modalType") || "success";
    function handleErrorClick()
    {
        history.push({
            pathname:"/"
        })
    }
    return (
        <div className="container m-w subContainer" style={{whiteSpace:"break-spaces",wordBreak:"break-word"}}>
            <div className="columns is-mobile">
                <div className="column is-full">
                {status==="success" &&
                <div>
                    <img src={success} alt="success" className="status-img"/>
                    <h1>{successModal?.title[language]}</h1>
                    <p>{successModal?.mainTitle[language]}</p>
                    <h2>{successModal?.subTitle[language]}</h2>
                </div>
                }
                {status==="failed" &&
                <div>
                    <img src={error} alt="success" className="status-img"/>
                    <h1>{errorModal?.title[language]}</h1>
                    <h2>{errorModal?.mainTitle[language]}</h2>
                    <p>{errorModal?.subTitle[language]}</p>
                    <button className="btn bold" onClick={handleErrorClick}>{lowBalModal?.btnText[language]}</button>
                </div>
                }
                  {(status==="pending" || status==="grace" || status==="lowBalance") &&
                <div>
                    <img src={lowBal} alt="success" className="status-img"/>
                    <h1>{lowBalModal?.title[language]}</h1>
                    <h2>{lowBalModal?.mainTitle[language]}</h2>
                    <button className="btn bold">{lowBalModal?.btnText[language]}</button>
                </div>
                } 
                </div>
            </div>
        </div>
    )
}

export default SubscriptionModal