import React, { useEffect } from 'react';
import './TermsAndConditions.scss';
import '../AppComponents/Subscription.scss';
import { useHistory } from 'react-router-dom';

const TermsAndConditions = ({ homeLogo }) => {

    const history = useHistory()
    const lang = localStorage.getItem("lang")
    const openLP =()=>{
        history.push("/")
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="terms-conditions-container">
            Coming soon...
        </div>
    );
}

export default TermsAndConditions;
