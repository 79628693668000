import React, { useEffect, useState } from "react";
import './Subscription.scss'
import BannerSlider from "../commonComponents/BannerSlider";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import config from "../config";
import { useLanguage } from "../Context/LangContext";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { reactLocalStorage } from "reactjs-localstorage";
import { SignInPageText as text } from "../Text";
import opco_logo from "../assets/images/telenity_logo.png";
import bng_logo from "../assets/images/bng_logo.png";
import SubscriptionLoader from "./SubscriptionLoader";
import SubModal from "./SubModal";
import { logEventHome } from "../Analytics/AnalyticsEvent";
import { screen } from "../Analytics/EventName";
import { SendGuiDataEvents } from "../CommonSript";
const FillOtp = (props) => {
  const banners = props.banners
  const { language } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [msisdn, setMsisdn] = useState('');
  const [otp, setOtp] = useState('');
  const [token,setToken]=useState("");
  const [subscriptionRate,setSubscriptionRate]=useState(reactLocalStorage.get("packSel")?JSON.parse(reactLocalStorage.get("packSel")):{});
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [showModal,setShowModal]=useState(false);
  const [message,setMessage]=useState("");
  const [btnText,setBtnText]=useState("");
  function handleModal()
  {
      setShowModal(prev=>!prev)
  }
  useEffect(() => {
    let guiEvents = {};
    guiEvents["page"] = "login";
    guiEvents["event"] = "open";
    SendGuiDataEvents(guiEvents);
    checkScript();
}, []);
const checkScript = () => {
  if (window.subscribe) {
    setIsScriptLoaded(true);
  } else {
    setTimeout(checkScript, 1000);
  }
};
  const handleMsisdnChange = (e) => {
    const value = e.target.value;
        
    if (/^\d*$/.test(value)) {
        setMsisdn(value);
    }
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;    
        if (/^\d*$/.test(value)) {
            setOtp(value);
        }
  };
  const passTokenAPI = () => {
    let guiEvents = {};
    guiEvents["page"] = "otp";
    guiEvents["event"] = "passToken_request";
    SendGuiDataEvents(guiEvents);      
    setLoading(true)
    const url = `${config.get('base')}${config.get('passToken')}`
    const headers={
        ...config.get('headers'),
        "Language":language
    }
    fetch(url, {
        method: 'POST',
        headers: headers
    })
        .then(res => {
            if (!res.ok) { throw res }
            return res.json()
        })
        .then((result) => {
            console.log('result message::', result);
            let guiEvents = {};
            guiEvents["page"] = "otp";
            guiEvents["event"] = "passToken_response";
            guiEvents["access_token"] = result?.access_token;
            SendGuiDataEvents(guiEvents);      
            setToken(result?.access_token);
            subsribe();
          
        },
            (error) => {
                console.log('error:::', error)
                let guiEventsError = {};
                guiEventsError["page"] = "otp";
                guiEventsError["event"] = "passToken_response_error";
                guiEventsError["error_status"] = error.status || "unknown";
                guiEventsError["error_message"] = error.statusText || "Error occurred during pass token ";
                SendGuiDataEvents(guiEventsError);
            })
}
  const isFormValid=(msisdn?.length>=8 || msisdn?.length<=14) && otp?.length===4;
  const history = useHistory();
  
  const matchOtp = () => {
    if (otp?.length === 4) {
     let guiEvents = {};
     guiEvents["page"] = "login";
     guiEvents["event"] = "verifyPin_request";
     SendGuiDataEvents(guiEvents);
      setLoading(true)
      const url = `${config.get('base')}${config.get('verifyPin')}`;
      const headers = {
        ...config.get('headers'),
        Language: language
      }
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({ 'msisdn': msisdn, "pin": otp }),
        headers: headers
      })
        .then(res => {
          if (!res.ok) { throw res }
          return res.json()
        })
        .then((result) => {
          console.log('result message::', result);
          let guiEvents1 = {};
          guiEvents1["page"] = "login";
          guiEvents1["event"] = "verifyPin_response";
          guiEvents1["status"] = result?.status;
          SendGuiDataEvents(guiEvents1);
          if (result?.status === "success") {
             if(['new','pending','grace'].includes(result?.subStatus?.toLowerCase()))
              {
                setLoading(false);    
                history.push("/signup");
              }
            else if(result?.subStatus?.toLowerCase() === "active")
              {
                reactLocalStorage.set("uid",result?.uid);
                reactLocalStorage.set("pinVerified",'true');
                checksub(result?.uid)
              }
          }
          else {
            setLoading(false);
            setShowModal(true);
            setMessage(result?.message)
            setBtnText(result?.btn_txt);
          }
        },
          (error) => {
            let guiEventsError = {};
            guiEventsError["page"] = "otp";
            guiEventsError["event"] = "passToken_response_error";
            guiEventsError["error_status"] = error.status || "unknown";
            guiEventsError["error_message"] = error.statusText || "Error occurred during verifyPin";
            SendGuiDataEvents(guiEventsError);
            console.log('error:::', error)
          })
    }
  }

  const subsribe = () => {
   
    let guiEvents = {};
    guiEvents["page"] = "otp";
    guiEvents["event"] = "subscribe_request";
    SendGuiDataEvents(guiEvents);
    const url = `${config.get('base')}${config.get('subscribe')}`
    const headers={
        ...config.get('headers'),
        'Language':language
    }
    fetch(url, {
        method: 'POST',
        body: JSON.stringify({ 'msisdn':  msisdn ,"Packtype":subscriptionRate?.packId}),
        headers: headers
    })
        .then(res => {
            if (!res.ok) { throw res }
            return res.json()
        })
        .then((result) => {
            console.log('result message::', result);
            let guiEvents1 = {};
            guiEvents1["page"] = "otp";
            guiEvents1["event"] = "checksub_response";
            guiEvents1["status"] = result?.subStatus;
            SendGuiDataEvents(guiEvents1);
            setLoading(false);
            callSubscribeFunction(`62${msisdn}`,subscriptionRate?.opcoPackId,token,"https://ayomaingame.com/thanks")                 
        },
            (error) => {
                console.log('error:::', error)
            })
}
  const callSubscribeFunction = (number, Packtype, bearer, payReturnURL) => {
    if (isScriptLoaded) {
      console.log("function called before");
      window.subscribe(number, Packtype, bearer, payReturnURL);
      console.log("function called after");
    } else {
      console.log("The subscribe function is not loaded yet.");
    }
  };
  const checksub = async(num) => {
    if(num  && num!=='undefined'){
      let guiEvents = {};
      guiEvents["page"] = "otp";
      guiEvents["event"] = "checksub_request";
      SendGuiDataEvents(guiEvents);
    const url = `${config.get('base')}${config.get('checkSub')}`
    const headers={
        ...config.get('headers'),
        Language:language
    }
    try {
          const res = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({ uid: num }),
            headers,
          });
    
          if (!res.ok) throw res;
    
          const result = await res.json();
          reactLocalStorage.set('subStatus', result?.subStatus);
          reactLocalStorage.set('status_display', result?.status_display);
          reactLocalStorage.set('showUnsub', result?.showUnsub);
          reactLocalStorage.set('msisdn', result?.msisdn);
          reactLocalStorage.set('unsubtext', result?.unsub_msg_txt);
          let guiEvents1 = {};
          guiEvents1["page"] = "otp";
          guiEvents1["event"] = "checksub_response";
          guiEvents1["status"] = result?.subStatus;
          SendGuiDataEvents(guiEvents1);
          if (result?.status === 'success') {
            if(result?.subStatus==="new")
              {
                 reactLocalStorage.set("initStatus",'new')
              }
              else if(result?.subStatus === 'inactive' && subscriptionRate)
              {
                passTokenAPI();                 
              }
             else if (result?.subStatus === 'active') {
              history.push("/home");
              
            } else {
              history.push('/login');
            }
          } 
          setLoading(false)
          logEventHome(
            {
              screen: screen.otpPage,
              event: 'checksub_api_success',
            },
            {
              status: result.subStatus || '',
              msisdn: result.msisdn || 'NA',
            }
          );
        } catch (error) {
          console.log('Error during subscription check:', error);
          let guiEventsError = {};
            guiEventsError["page"] = "otp";
            guiEventsError["event"] = "checksub_response_error";
            guiEventsError["error_status"] = error.status || "unknown";
            guiEventsError["error_message"] = error.statusText || "Error occurred during checksub";
            SendGuiDataEvents(guiEventsError);
        }
    }
}; 
  const settings = {
    dots: true,
    infinite: true,
    speed: 500
  };

  if (loading) return <SubscriptionLoader/>;
  return (
    <div className="container" style={{ whiteSpace: "break-spaces" }}>
      {banners && banners.length ? (
        <BannerSlider
          settings={{
            ...settings,
                    arrows: false,
                    dots:true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: true,
                    infinite: true,
                    swipe: true,
                    autoplay: true,
                    speed: 200,
                    autoplaySpeed: 1500,
                    cssEase: "linear",
                        customPaging: () => <div className="dot-outer"><div className="dot"></div></div>,
          }}
          data={banners} />) : ""}
                  <div className="mainTitle">
                    <h1>Dipersembahkan Oleh</h1>
                        <img src={bng_logo} alt="bng logo" className="ml-1"/>        
                    <h1>Diselenggarakan Oleh</h1>
                    <img src={opco_logo} alt="bng logo" className="ml-1"/>
                    </div>
      <p className="text">
        {text.title[language]}
      </p>

      <form className='form-container'>
        <div className="inputBox">
          <label htmlFor="msisdn">  {text?.msisdnText[language]}</label>
        <div className="msisdn-field">
          <p style={{ padding: '10px', backgroundColor: '#eee', border: '1px solid #ccc', borderRight: 'none' }}>
        +62
         </p>
          <input
            type="text"
            id="msisdn"
            placeholder={text.msisdnPlaceholder[language]}
            name="msisdn"
            value={msisdn}
            onChange={handleMsisdnChange}
            autoComplete="off"
            />
           </div>
        </div>
        <div className="inputBox pb-6">
          <label htmlFor="otp">{text?.pinText[language]}</label>
          <input
            type="text"
            placeholder={text.pinPlaceholder[language]}
            id="otp"
            name="otp"
            value={otp}
            onChange={handleOtpChange}
            autoComplete="off"
          />
        </div>
        <button className="btn bold" onClick={matchOtp} disabled={!isFormValid}>
        {text.logInBtnText[language]}
        </button>
      </form>
       <p className="singUp">{text.singUpText[language]} <Link to="/signup">{text?.signUpBtnText[language]}</Link>{" "}{text?.hereText[language]}</p>
       {showModal && <SubModal message={message} btnText={btnText} handleClick={handleModal}/>}
    </div>
  )
}

export default FillOtp