import React from "react"
import { useHistory } from "react-router-dom";
import RatingGray from "./../assets/icons/star_gray.png"
import back from "./../assets/icons/back.png"
import "./BannerGame.scss"
import { Link } from "react-router-dom"
import defaultImage from "../assets/images/default-image.png"
import { reactLocalStorage } from 'reactjs-localstorage';
import { logEvent } from "../Analytics/AnalyticsEvent";
import { events, screen } from "../Analytics/EventName";
import RatingYellow from "./../assets/icons/complete.png";
import RatingQuatar from "./../assets/icons/quatar.png";
import RatingHalf from "./../assets/icons/half.png";
import remote from "./../assets/icons/play_now_cta_icon.png";
import { SendGuiDataEvents } from "../CommonSript";
function BannerGame(props) {
    console.log(">>>props::", props)
    const { game, setIsLoading, logo } = props
    let history = useHistory();
    function rating(number) {
        const num = parseFloat(number)
        let newArray = Array(5).fill().map((_, i) => {
            return <img key={i} src={RatingYellow} alt="stars" className="mr-1 rating-icon" />
        })
        newArray[4] = num == 4 ? (<img src={RatingGray} alt="stars" className="mr-1 rating-icon" />) :
            num >= 4.0 && num <= 4.2 ? <img src={RatingQuatar} alt="stars" className="mr-1 rating-icon" /> :
                num >= 4.3 && num <= 4.5 ? <img src={RatingHalf} alt="stars" className="mr-1 rating-icon" /> :
                    num >= 4.5 && num <= 4.7 ? <img src={RatingHalf} alt="stars" className="mr-1 rating-icon" /> :
                        <img src={RatingYellow} alt="stars" className="mr-1 rating-icon" />
        return newArray;

    }
    function addDefaultSrc(e) {
        e.target.src = defaultImage
    }

    function handleClick(gameUrl, game) {
        setIsLoading(true);
        let guiEvents={}
        guiEvents["page"]="game";
        guiEvents["event"]="game_redirect";
        guiEvents['gameName']=game.gameName;
        SendGuiDataEvents(guiEvents)

        logEvent(
            {
                screen: screen.gamePage,
                event: game.gameName
            },
            {
                gameName: game.gameName,
                mobile: reactLocalStorage.get('msisdn'),
                date: new Date(),
            });
        const subStatus = reactLocalStorage.get("subStatus");
        if (subStatus === 'active') {
            window.open(gameUrl, "_self")
        } else {
            history.push("/");
        }
    }
    return (
        <div className="is-gapless pagebanner" style={{ width: "100%", whiteSpace: "break-spaces", bottom: "-2rem" }} >
            <div className="banner-container">
                <Link to="/home" className="banner-header" >
                    <img src={back} alt="back to home" className="back-button" />
                    <img src={logo} alt="main logo" className="service-logo" />
                </Link>
                <img
                    src={game.bannerImage}
                    onError={addDefaultSrc}
                    alt="demo"
                    height="300px"
                    width="100%"
                    style={{ minHeight: "30vh", maxHeight: "40vh" }}
                    className="banner-image"
                />
            </div>
            <div className="media">
                <div className="desc" >
                    <div className="media-left align-left" >
                        <h5 className="title is-capitalized is-5 pb-2 has-text-white mb-4 bold">{game.gameName}</h5>
                        <h6 className="subtitle is-7 has-text-white mb-0">{game.gamePlayed}</h6>
                        <div className="rating has-text-white">
                            {rating(game.ratings)}
                            <small className="has-text-weight-dark">
                                {game.ratings}
                            </small>
                        </div>
                    </div>
                </div>s
                <div className="media-right" onClick={() => handleClick(game.url, game)} >
                    <button className="btn">
                        <img src={remote} alt="remote" className="remote-icon mr-1" />
                        <span className="bold">{props?.play_text}</span>
                    </button>
                </div>
            </div>
            {/* </div> */}
            <div className="column">
                <p className="subtitle is-6 has-text-white game-desc" >{game.gameDescription}</p>
            </div>
        </div>
    )
}

export default BannerGame