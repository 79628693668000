import React from "react";
import './Info.scss';
import successIcon from "../assets/icons/sad_see_you_go_icon.png"
import { useHistory } from "react-router-dom";

const Sorry =(props)=>{

    const lang = localStorage.getItem("lang")
    const homeLogo = props.homeLogo
    const history = useHistory()

    const goToLanding=()=>{
        history.push("/subs")
    }
    return (
        <div className="containerinfo">
            <img src={homeLogo}
            className="logoinfo">
            </img>
            <div className="icon-container">
                <img className="tick" src={successIcon} alt="Success" />
            </div>
            <h1 className="heading">{lang === "en" ? "Sad to see you go" : "حزين لرؤيتك ذاهب"}</h1>
            <p className="para">{lang === "en" ?"Remember cubegames is here whenever you need it" : "تذكر أن cubegames موجودة عندما تحتاج إليها"}</p>
            <button className="btninfo" onClick={goToLanding}>{lang === "en" ? "Go to home" : "اذهب إلى المنزل"}</button>

            <div className="foot">
        </div>
        </div>
    );
}

export default Sorry