import React, { useEffect, useState } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Home.scss"
import slider from "../assets/icons/slider.svg"
import BannerSlider from "../commonComponents/BannerSlider"
import CommonSlider from "../commonComponents/CommonSlider"
import config from "../config"
import { reactLocalStorage } from 'reactjs-localstorage'
import Modal from 'react-modal';
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useLanguage } from "../Context/LangContext"
import GameCategorySlider from "../commonComponents/GamesCategorySlider"

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%'
    },
};

Modal.setAppElement('#root');


function Home({ games,banners,view_btn_txt,loading}) {
    const {allGamesJson,setSelectedCategory}=useLanguage();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [heMsg, setHeMsg] = useState('')
    let history = useHistory();
    
   
    const settings = {
        dots: true,
        infinite:true,
        speed: 500
    };
  

    function capatalize(title) {
        return title?.charAt(0)?.toUpperCase() + title?.slice(1);
    }

    useEffect(() => { 
            reactLocalStorage.remove("category-clicked");
            setSelectedCategory("")       
    }, []);

    function closeModal() {
        setIsOpen(false);
    }

    function handleClick() {
        if (config.get('domain') === 'btc') {
            window.location.reload();
        } else {
            history.push('/login')
        }
    }

    return (
        <>
        {
            loading?<div className="loader-container">
            <div className="lds" >
            </div>
            </div>:
        
        <div className="container m-w">
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div>{heMsg}</div>
                <button
                    onClick={handleClick}
                    style={{
                        width: '23%',
                        backgroundColor: 'rgb(23 54 206)',
                        color: 'white',
                        padding: '4% 1%',
                        margin: '8px 0',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontWeight: 'bold'
                    }}
                >Okay</button>
            </Modal>
            {allGamesJson &&
              <GameCategorySlider
                settings={{
                  ...settings,
                  dots: false,
                  arrows: false,
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  centerPadding: "10%",
                  swipeToSlide: true,
                }}
                data={allGamesJson}
              />
            }
            {banners && banners.length?(
            <BannerSlider
                settings={{
                    ...settings,
                    arrows: false,
                    dots:true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: true,
                    infinite: true,
                    swipe: true,
                    autoplay: true,
                    speed: 200,
                    autoplaySpeed: 1500,
                    cssEase: "linear",
                        customPaging: () => <div className="dot-outer"><div className="dot"></div></div>,
                        onSwipe: () => slider.current.slickPause()
                }}
                data={banners}/>):""} 
               
            {games &&
            _.map(games).map((item, index) => (
              <CommonSlider
                settings={{
                  ...settings,
                  dots: false,
                  arrows: false,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  centerPadding: "10%",
                  swipeToSlide: true,
                }}
                title={capatalize(item.title)}
                data={item.contents}
                key={index}
                category={item.title}
                view_btn_txt={view_btn_txt}
              />
            ))}

        </div>}
        </>
    )
}

export default Home