
export function concatAction({gameName, mobile, date, others }) {
    console.log('--------others------->', others);
    let str
    if(mobile!==undefined && mobile!=='undefined' && mobile!=='' && mobile!==null){
         str = `GameName - ${gameName}, mobile - ${mobile}, date - ${date}`
    }else{
         str = `GameName - ${gameName}, date - ${date}`
    }
   
    for (const property in others) {
        str += `, ${property} - ${others[property]}`;
    }
    return str
}

export function concatActionHome({others }) {
    let str= `date - ${new Date()}`
    for (const property in others) {
        str += `, ${property} - ${others[property]}`;
    }
    return str
}