export const events = {
    open: "open",
    gameCardClick: "gameCardClick",
    gamePlayClick: "gamePlayClick",
}
export const screen = {
    homePage: "homePage",
    gamePage : "gamePage",
    otpPage:"otpPage",

}


