import React,{useEffect} from 'react';
import { useLanguage } from '../Context/LangContext';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import selected from "../assets/images/language_select.png";
import deselect from "../assets/icons/language_deselect.png";
import { SendGuiDataEvents } from '../CommonSript';
const Language = ({langObj }) => {
  let history=useHistory();
  const { language, changeLanguage } = useLanguage(); 
  const onLanguageSelect = (lang) => {
    reactLocalStorage.set('lang','en');
    changeLanguage(lang); 
    history.push("/home")
  };
  useEffect(()=>
  {
    let guiEvents = {};
    guiEvents["page"] = "language";
    guiEvents["event"] = "open";
    SendGuiDataEvents(guiEvents);
  },[]);

  return (
    <div className="container m-w">
    <div className="columns is-mobile">
        <div className="column is-full">
            <div className="">
                <div className=" ">
                    <div className="display">
                       <div className="language-options">
            {langObj?.option?.map((option) => (
             <div
               key={option.id} 
               className={`language-option text-lg bold ${
                 language === option.key ? 'selected' : ''
               }`} 
               onClick={() => onLanguageSelect(option.key)} 
             >
                <div>{language === option.key? <img src={selected} className='lang-icon' alt="selected"/>:
                <img src={deselect}  className="lang-icon" alt="deselect"/>
                }
                </div>
               <div className='lang-code'>{option.lang}</div> 
             </div>
           ))}
         </div>
           </div>
          </div>
            </div>
        </div>
    </div>
   </div>
  )
};
export default Language;