import React, { useEffect, useState } from "react";
import './Subscription.scss'
import BannerSlider from "../commonComponents/BannerSlider"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { reactLocalStorage } from "reactjs-localstorage";
import config from "../config";
import { useLanguage } from "../Context/LangContext";
import { logEventHome } from "../Analytics/AnalyticsEvent";
import { screen } from "../Analytics/EventName";
import { Link } from 'react-router-dom';
import opco_logo from "../assets/images/telenity_logo.png";
import bng_logo from "../assets/images/bng_logo.png";
import { SignInPageText as text, SingUpPageText as singUpText } from "../Text";
import SubscriptionLoader from "./SubscriptionLoader";
import { hitCallBackApi } from "../hooks/useHitCallBackApi";
import { SendGuiDataEvents } from "../CommonSript";
const Subscription = ({ banners }) => {
  const [msisdn, setMsisdn] = useState("");

  const { language } = useLanguage();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptionLoader, setSubscriptionLoader] = useState(false);
  const history = useHistory();
  const [token, setToken] = useState("");
  const rcid = reactLocalStorage.get('rcid');
  const camp = reactLocalStorage.get('camp');
  const [packType, setPackType] = useState(reactLocalStorage.get("packType"));
  const filteredPacks = singUpText?.packs?.find(pack => pack.packId === packType);
  console.log("filteredPacks:::", filteredPacks)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500
  };

  const handleApiCall = (num, camp, rcid) => {
    hitCallBackApi(num, camp, rcid)
      .then(result => {
        console.log('API call successful:', result);
        setLoading(false)
        history.push("/home")
      })
      .catch(error => {
        console.error('API call failed:', error);
        let guiEventsError = {};
        guiEventsError["page"] = "signup";
        guiEventsError["event"] = "callback_response_error";
        guiEventsError["error_status"] = error.status || "unknown";
        guiEventsError["error_message"] = error.statusText || "Error occurred during callback";
        SendGuiDataEvents(guiEventsError);
      });
  };


  const checkScript = () => {
    if (window.subscribe) {
      setIsScriptLoaded(true);
      // Now you can call window.subscribe here if needed immediately after loading
    } else {
      // Retry after a short delay
      setTimeout(checkScript, 1000);
    }
  };

  const isFormValid = msisdn.length >= 8 && msisdn.length <= 13;
  const handleMsisdnChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      setMsisdn(value);
    }
  };
  useEffect(() => {
    let guiEvents = {};
    guiEvents["page"] = "signup";
    guiEvents["event"] = "open";
    SendGuiDataEvents(guiEvents);
    passTokenAPI();
    checkScript();
  }, []);

  const callSubscribeFunction = (number, Packtype, bearer, payReturnURL) => {
    if (isScriptLoaded) {
      // Assuming the subscribe function requires some parameters, pass them as needed
      console.log("function called before");
      window.subscribe(number, Packtype, bearer, payReturnURL);
      console.log("function called after");
    } else {
      console.log("The subscribe function is not loaded yet.");
    }
  };
  const passTokenAPI = () => {
    let guiEvents = {};
    guiEvents["page"] = "signup";
    guiEvents["event"] = "passToken_request";
    SendGuiDataEvents(guiEvents);
    setLoading(true)
    const url = `${config.get('base')}${config.get('passToken')}`
    const headers = {
      ...config.get('headers'),
      "Language": language
    }
    fetch(url, {
      method: 'POST',
      headers: headers
    })
      .then(res => {
        if (!res.ok) { throw res }
        return res.json()
      })
      .then((result) => {
        console.log('result message::', result);
        let guiEvents1 = {};
        guiEvents1["page"] = "signup";
        guiEvents1["event"] = "passToken_response";
        guiEvents1["access_token"] = result?.access_token;
        SendGuiDataEvents(guiEvents1);
        setLoading(false)
        setToken(result?.access_token)

      },
        (error) => {
          console.log('error:::', error);
          let guiEventsError = {};
          guiEventsError["page"] = "signup";
          guiEventsError["event"] = "passToken_response_error";
          guiEventsError["error_status"] = error.status || "unknown";
          guiEventsError["error_message"] = error.statusText || "Error occurred during passToken";
          SendGuiDataEvents(guiEventsError);
        })
  }


  const checksub = async (num) => {
    if (num && num !== 'undefined') {
      let guiEvents = {};
      guiEvents["page"] = "signup";
      guiEvents["event"] = "checksub_request";
      SendGuiDataEvents(guiEvents);
      setSubscriptionLoader(true)
      const url = `${config.get('base')}${config.get('checkSub')}`
      const headers = {
        ...config.get('headers'),
        Language: language
      }
      try {
        const res = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({ msisdn: num }),
          headers,
        });

        if (!res.ok) throw res;

        const result = await res.json();
        reactLocalStorage.set('subStatus', result?.subStatus);
        reactLocalStorage.set('status_display', result?.status_display);
        reactLocalStorage.set('showUnsub', result?.showUnsub);
        reactLocalStorage.set('msisdn', num);

        reactLocalStorage.set('unsubtext', result?.unsub_msg_txt);
        let guiEvents1 = {};
        guiEvents1["page"] = "signup";
        guiEvents1["event"] = "checksub_response";
        guiEvents1["status"] = result?.subStatus;
        SendGuiDataEvents(guiEvents1);
        if (result?.status === 'success') {
          if (result?.subStatus === "new" || result?.subStatus === "inactive") {
            reactLocalStorage.set("initStatus", 'new')
            subsribe();
          }
          else if (result?.subStatus === 'active') {
            reactLocalStorage.set('uid', result?.uid);
            if (reactLocalStorage.get("initStatus") === "new" && rcid && camp) {
              handleApiCall(num, camp, rcid)
            }
            else {
              setLoading(false)
              history.push(`${camp && rcid ? '/signup' : '/login'}`);
            }
          } else {
            setLoading(false)
            history.push(`${camp && rcid ? '/signup' : '/login'}`);
          }
        }
        else {
          setLoading(false)
        }
        logEventHome(
          {
            screen: screen.otpPage,
            event: 'checksub_api_success',
          },
          {
            status: result.subStatus || '',
            msisdn: result.msisdn || 'NA',
          }
        );
      } catch (error) {
        console.log('Error during subscription check:', error);
        let guiEventsError = {};
        guiEventsError["page"] = "signup";
        guiEventsError["event"] = "checksub_response_error";
        guiEventsError["error_status"] = error.status || "unknown";
        guiEventsError["error_message"] = error.statusText || "Error occurred during callback";
        SendGuiDataEvents(guiEventsError);
      }
    }
  };

  const handleClick = () => {
    checksub(msisdn)
  }

  const subsribe = () => {
    if (isFormValid) {
      let guiEvents = {};
      guiEvents["page"] = "signup";
      guiEvents["event"] = "subscribe_request";
      SendGuiDataEvents(guiEvents);
      setSubscriptionLoader(true)
      const url = `${config.get('base')}${config.get('subscribe')}`
      const headers = {
        ...config.get('headers'),
        'Language': language
      }
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({ 'msisdn': msisdn, "Packtype": filteredPacks?.packId }),
        headers: headers
      })
        .then(res => {
          if (!res.ok) { throw res }
          return res.json()
        })
        .then((result) => {
          console.log('result message::', result);
          let guiEvents1 = {};
          guiEvents1["page"] = "signup";
          guiEvents1["event"] = "checksub_response";
          guiEvents1["status"] = result?.subStatus;
          SendGuiDataEvents(guiEvents1);
          setSubscriptionLoader(false);
          handleApiCall(`62${msisdn}`, camp, rcid)
          callSubscribeFunction(`62${msisdn}`, filteredPacks?.opcoPackId, token, 'https://ayomaingame.com/thanks',)
        },
          (error) => {
            console.log('error:::', error)
          })
    }
  }
  if (subscriptionLoader) return <SubscriptionLoader />;
  return (
    <>
      {loading ? <div className="loader-container">
        <div className="lds" ></div>
      </div> :
        <div className="container" >
          {banners && banners.length ? (
            <BannerSlider
              settings={{
                ...settings,
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                swipeToSlide: true,
                infinite: true,
                swipe: true,
                autoplay: true,
                speed: 200,
                autoplaySpeed: 2000,
                cssEase: "linear",
                customPaging: () => <div className="dot-outer"><div className="dot"></div></div>,
              }}
              data={banners} />) : ""}
          <div className="mainTitle">
            <h1>Dipersembahkan Oleh</h1>
            <img src={bng_logo} alt="bng logo" className="ml-1" />
            <h1>Diselenggarakan Oleh</h1>
            <img src={opco_logo} alt="bng logo" className="ml-1" />
          </div>
          <p className="text">
            {text.title[language]}
          </p>
          <div className="pack-container">
            <div className={`pack pack-selected`}>
              <h3>{filteredPacks?.packName[language]}</h3>
              <p>{filteredPacks?.packPrize}</p>
            </div>
            <div className="inputBox pb-6 inputMsisdn">
              <label htmlFor="msisdn">  {text?.msisdnText[language]}</label>
              <div className="subMsisdn">
                <p style={{ padding: '10px', backgroundColor: '#eee', border: '1px solid #ccc', borderRight: 'none' }}>
                  +62
                </p>
                <div>
                  <input
                    type="text"
                    id="msisdn"
                    placeholder={text.msisdnPlaceholder[language]}
                    name="msisdn"
                    value={msisdn}
                    onChange={handleMsisdnChange}
                  />
                </div>
              </div>
            </div>
            <button className="btn bold" disabled={!isFormValid} onClick={handleClick}>
              {singUpText?.subscribeBtnText[language]}
            </button>
          </div>
          <p className="singUp">{singUpText.loginSubText[language]} <Link to="/login" style={{ textDecoration: 'none' }}>{singUpText?.loginText[language]}</Link>{" "}{singUpText?.hereText[language]}</p>
        </div>
      }
    </>
  )
}

export default Subscription