import React from "react";
import "./GameCard.scss";
import RatingGray from "./../assets/icons/star_gray.png";
import RatingYellow from "./../assets/icons/complete.png";
import { Link } from "react-router-dom";
import { logEvent } from "../Analytics/AnalyticsEvent";
import { screen } from '../Analytics/EventName';
import { reactLocalStorage } from "reactjs-localstorage";
import RatingQuatar from "./../assets/icons/quatar.png";
import RatingHalf from "./../assets/icons/half.png";
import remote from "./../assets/icons/play_now_cta_icon.png";

function GameCard(props) {
    const { data, play_text } = props
    function rating(number) {
        const num = parseFloat(number)
        let newArray = Array(5).fill().map((_, i) => {
            return <img key={i} src={RatingYellow} alt="stars" className="mr-1 rating-icon" />
        })
        newArray[4] = num == 4 ? (<img src={RatingGray} alt="stars" className="mr-1 rating-icon" />) :
            num >= 4.0 && num <= 4.2 ? <img src={RatingQuatar} alt="stars" className="mr-1 rating-icon" /> :
                num >= 4.3 && num <= 4.5 ? <img src={RatingHalf} alt="stars" className="mr-1 rating-icon" /> :
                    num >= 4.5 && num <= 4.7 ? <img src={RatingHalf} alt="stars" className="mr-1 rating-icon" /> :
                        <img src={RatingYellow} alt="stars" className="mr-1 rating-icon" />
        return newArray;

    }
    return (
        <div className="game-info-card">
            <img src={data.gameImage} alt="Placeholder" className="img-radius banner" style={{ minHeight: "100px" }} />
            <div className="card-info">
                <h3 className=" is-capitalized is-6 text-white text-left " style={{ whiteSpace: "break-spaces" }}>{data.gameName}</h3>
                <p className=" is-capitalized is-6 text-white">{data?.gamePlayed}</p>
                <div className="text-left mr">
                    <small className="" style={{ marginTop: 0 }}>
                        {rating(data?.ratings)}
                        <small className="has-text-weight-dark">
                            {data.ratings}
                        </small>
                    </small>
                </div>
            </div>
            <button className="btn mt-3">
                <Link to={`/game/${data?.category}/${data?.id}`}
                    onClick={() => {
                        console.log("data.gameName", data?.gameName + "_cardClick")
                        logEvent(
                            {
                                screen: screen.homePage,
                                event: data?.gameName + "_cardClick"
                            },
                            {
                                gameName: data?.gameName,
                                mobile: reactLocalStorage.get('msisdn'),
                                date: new Date(),
                            });
                    }}>
                    <img src={remote} alt="remote" className="remote-icon" />
                    <span className="my-auto ml-2 bold text-white">{props?.play_text}</span>
                </Link>
            </button>
        </div>
    )
}

export default GameCard