import React, { createContext, useContext, useState } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';

const LanguageContext = createContext();


export const LanguageProvider = ({ children }) => {

  const [language, setLanguage] = useState(reactLocalStorage.get('lang') || 'id');
  const [allGamesJson,setAllGamesJson]=useState({})
  const [selectedCategory,setSelectedCategory]=useState(reactLocalStorage.get('category-clicked') || '');
  const [headerTitle,setHeaderTitle]=useState("")
  const changeLanguage = (lang) => {
   
    reactLocalStorage.set('lang', lang);
    setLanguage(lang);
  };
  console.log("lang here is:::",language)
  return (
    <LanguageContext.Provider value={{ language, changeLanguage,allGamesJson,setAllGamesJson,selectedCategory,setSelectedCategory,headerTitle,setHeaderTitle}}>
      {children} 
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
