import React,{useEffect} from "react"
import { FadeLoader } from 'react-spinners';
import {ProcessingScreenText as txt} from "../Text";
import { useLanguage } from "../Context/LangContext";
function SubscriptionLoader() {
const {language}=useLanguage();


return (
  <div className="container m-w">
     <div className="columns is-mobile">
     <div className="column is-full">
      <div className="sub-loader pb-6">
      <FadeLoader color={"#6C69F6"} loading={true} size={200} radius={6} height={30} width ={8} margin={20}/>
      </div>
      <h1 className="text-white text-2xl bold">{txt.title[language]}</h1>
      <p className="text-white text-lg">{txt.subTitle[language]}</p>
      <p className="text-white text-lg">{txt.subTitleText[language]}</p>
      </div>
      </div>
  </div>
)
}

export default SubscriptionLoader
