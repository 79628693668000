const SignInPageText={
    title:{
        en:"LEVEL UP YOUR FUN!",
        id:"Tingkatkan kesenangan Anda"
    },
    msisdnText:
    {
         en:"Please Enter Phone Number",
         id:"Silakan Masukkan Nomor Telepon"
    },
    msisdnPlaceholder:
    {
         en:"123XXXXXXX",
         id:"123XXXXXXX"
    },
    pinPlaceholder:
    {
         en:"97XX",
         id:"97XX"
    },
    pinText:{
        en:"Please Enter Your PIN",
        id:"Harap Masukan Pin Anda"
    },
    logInBtnText:
    {
        en:"Log In",
        id:"Masuk"
    },
    signUpBtnText:
    {
        en:"Sign Up",
        id:"Daftar"
    },
    singUpText:{
        en:"Don't have an account?",
        id:"Belum punya akun?"
    },
    hereText:
    {
        en:"Here",
        id:"Disini"
    }
};

const SingUpPageText={
    packs:
    [
    {
       id :1,
       packName:{
        en:"Weekly Subscription",
        id:"Langganan mingguan"
       },
       packId:"weekly",
       opcoPackId:"Ayomain Weekly",
       packPrize:"5000 IDR"
    },
    {
        id :2,
        packName:{
         en:"Monthly Subscription",
         id:"Langganan bulanan"
        },
        packId:"monthly",
        opcoPackId:"Ayomain Monthly",
        packPrize:"10000 IDR"
    }],
    subscribeBtnText:
    {
        en:"Subscribe Now",
        id:"Langganan"
    },
    loginSubText:{
        en:"Have an account?",
        id:"Punya akun."
    },
    loginText:{
        en:"Login",
        id:"Masuk"
    },
    hereText:
    {
        en:"Here",
        id:"disini"
    }

}
const ProcessingScreenText={
    title:
    {
        en:"Processing Page",
        id:"Halaman Pemrosesan"
    },
    subTitle:
    {
        en:"Requested subscription is being processed.",
        id:"Langganan yang diminta sedang diproses."
    },
    subTitleText:
    {
        en:"Kindly wait and dont refresh.",
        id:"Harap tunggu dan jangan refresh."
    }
};
const SubscriptionModalText={
   successModal:
   {
     title:
     {
        en:"Thank you!",
        id:"Terima kasih!"
     },
     mainTitle:
     {
        en:"Your subscription request is received.",
        id:"Permintaan berlangganan Anda diterima."
     },
     subTitle:
     {
        en:"You will get notification when payment is completed.",
        id:"Permintaan berlangganan mingguan Anda telah diterima."
     }
   },
   lowBalModal:
   {
     title:
     {
        en:"Low Balance!",
        id:"Saldo Rendah!"
     },
     mainTitle:
     {
        en:"Sorry you have low balance.Kindly recharge and try again later.",
        id:"Maaf saldo Anda rendah. Harap lakukan isi ulang dan coba lagi nanti."
     },
     
     btnText:
     {
        en:"Ok",
        id:"Oke"
     }
   },
   errorModal:
   {
     title:
     {
        en:"Error!",
        id:"Kesalahan!"
     },
     mainTitle:
     {
        en:"Requested subscription could not be processed.",
        id:"Langganan yang diminta tidak dapat diproses."
     },
     subTitle:
     {
        en:"Please try again later",
        id:"Harap coba lagi nanti"
     },
     btnText:
     {
        en:"Ok",
        id:"Oke"
     }
   }
}
export {
    SignInPageText,
    SingUpPageText,
    ProcessingScreenText,
    SubscriptionModalText
};