import React from 'react'
import close from "../assets/icons/popup_close_icon.png";
export default function SubModal({message,btnText,handleClick}) {
  return (
    <div className="pop-bg container is-gapless pagebanner column p-r" >
    <div className="popup-modal">
      <img src={close} alt="close" className='model-close' onClick={()=>handleClick()} />
      <div className="modal-content">
                  <h1 className="column p-r bold">
                   {message}
                  </h1>
                  {btnText && <button className="btn mt-3"
            onClick={() => handleClick()}>
            <span className="my-auto ml-2 bold text-white">{btnText}</span>         
        </button>}
        </div>
      </div>
      </div>
  )
}
