import React from 'react'

export default function HomeLoader() {
    return (
        <div className={`hero is-fullheight bg`}>
            <div className="loader-container">
                <div className="lds" >
                </div>
            </div>
        </div>
    )
}
