import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import back from "./../assets/icons/back.png";
import closeIcon from "../assets/icons/menu_close_icon.svg";
import "../AppComponents/SideMenu.scss";
import profile from "../assets/icons/profile_menu_icon.svg";
import language from "../assets/icons/language_menu_icon.png";
import unsub from "../assets/icons/unsubscribe_menu_icon.svg";
import menu from "../assets/icons/menu_home_icon.svg"
import { useLanguage } from "../Context/LangContext";
function Header({setshowSideMenu,menu_color,menuLogo,view_btn_color,homeLogo,sidemenu }) {
  const [sideMenu, setSideMenu] = useState(false);
  let { pathname } = useLocation();
  const history = useHistory();
  const {headerTitle,setHeaderTitle}=useLanguage();
  const category_clicked=reactLocalStorage.get("category-clicked");
  
  useEffect(()=>
  {
      if(pathname==="/profile")
        {
          setHeaderTitle(sidemenu?.profile)
        }
      else if(pathname==="/language")
        {
          setHeaderTitle(sidemenu?.language?.title)
        }
      else if(pathname==="/unsub")
          {
            setHeaderTitle(sidemenu?.unsub)
          }
      else if(pathname==="/category")
            {
              setHeaderTitle(category_clicked)
            }
      else if(pathname==="/login" || pathname==="/signup")
              {
                setHeaderTitle("")
              }
        
  },[pathname,category_clicked])
  return (
    <nav
      className="navbar"
      role="navigation"
      aria-label="main navigation"
      style={{ display: "flex", flexDirection: "row",position:'relative',zIndex:1}}
    >
   {['/home','/'].includes(pathname) ? (
  <>
    <div
      className="p-4 fixed-width"
      onClick={() => {
        setSideMenu(true);
      }}
    >
      <img src={menu} alt="menu" />
    </div>
    <div className="navbar-brand fixed-width">
      {menuLogo && (
        <div className="p-2">
          <img src={menuLogo} alt="logo" />
        </div>
      )}
    </div>
  </>
) : pathname === "/thanks" ? (
  <div
    className="p-2"
    style={{
      display: "flex",
      width: '100%',
      alignItems: 'center',
      justifyContent: "flex-end",
    }}
  >
    <img src={homeLogo} alt="logo" />
  </div>
) : ['/signup','/login'].includes(pathname) ? (
  <div
    className="p-2"
    style={{
      display: "flex",
      width: '100%',
      alignItems: 'center',
      justifyContent: "center",
    }}
  >
    <img src={menuLogo} alt="logo" />
  </div>
) : (
  <div
    className="navitem fixed-width p-2"
    style={{
        display: "flex",
        width:'100%',
        alignItems:'center',
        justifyContent:'space-between'
    }}
    onClick={() => {
      if (pathname === "/category") {
        history.push("/home");
      } else {
        history.goBack();
      }
      setshowSideMenu(true);
    }}
  >
    <div className="navbar-brand">
    <img src={back} alt="back to home" style={{ height: '30px', margin: 'auto' }} />
    <p className="text-white bold">{headerTitle}</p>
    </div>
    <div>
    <img src={homeLogo} alt="logo" className="serviceLogo" />
    </div>
  </div>
)}

      {sideMenu && (
        <div className="ld-bg">
          <div
            className="sideMenu"
            style={{ background:menu_color }}
          >
            <div className="line-bottom" style={{background:view_btn_color}}>
            
              <div className="p-4 display menu-logo-img">
                <div className="" style={{ margin:'auto'}}>
                  <img src={menuLogo} alt="logo" style={{height:"40px"}} />
                </div>
                <div
                style={{ float: "right" }}
                onClick={() => setSideMenu(false)}
              >
                <img src={closeIcon} alt="close" />
              </div>
              </div>
            </div>
            {sidemenu?.profile && <Link  to="/profile" className="links text-lg text-white p-4 line-bottom justifyContent" onClick={()=>setSideMenu(false)}>
            <img src={profile} alt="profile" className="menu-icon"/>
            <span className="link">
             {sidemenu?.profile}
            </span></Link>}
          {
           sidemenu?.unsub && reactLocalStorage.get("subStatus")==='active' && reactLocalStorage.get("msisdn")   &&
            <Link  to="/unsub" className="links text-lg text-white p-4 line-bottom justifyContent" onClick={()=>
                { 
                setSideMenu(false)}}>
                  <img src={unsub} alt="unsubscribe" className="menu-icon"/>
                  <span className="link">{sidemenu?.unsub}</span></Link>     
           }
          {sidemenu?.language && <Link  to="/language" className="links text-lg text-white p-4 line-bottom justifyContent" onClick={()=>
            {
              setSideMenu(false)
           }}>
            <img src={language} alt="language" className="menu-icon"/>
            <span className="link">
            {sidemenu?.language?.title}
            </span>
            </Link>
}
          </div>
        </div>
      )}
    </nav>
  );
}

export default Header;
