import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../AppComponents/Games.scss";
import "../commonComponents/GameCard.scss";
import _ from "lodash";
import GameCard from "./../commonComponents/GameCard";
import { useLocation } from 'react-router-dom';
import GameCategorySlider from "./GamesCategorySlider";
import { reactLocalStorage } from "reactjs-localstorage";
import { useLanguage } from "../Context/LangContext";
function Category({play}) {
    const location = useLocation()   
    const [curData,setCurData]=useState([])
    const [isLoading,setIsLoading]=useState(true)
    const {allGamesJson,setSelectedCategory}=useLanguage();
    const [categoryName,setCategoryName]=useState(reactLocalStorage.get("category-clicked")|| '') 
    useEffect(()=>
    {
        let items=JSON.parse(localStorage.getItem('category_data'));
        setCurData(location.state?location.state.categoryData?.contents:items?.contents)
        const timer=setTimeout(()=>
        {
             setIsLoading(false)
        },1000)
        
        return()=>
            {
                clearTimeout(timer)
                // reactLocalStorage.remove("category-clicked")
                // setSelectedCategory("")
            } 
    },[]);
     console.log('inside category container::',curData)
     function capatalize(categoryName) {
        return categoryName.charAt(0).toUpperCase() + categoryName.slice(1);
    }
    useEffect(() => {
        if(curData!='undefined' && curData!='' && curData!=null && curData!=[])
        {
            localStorage.setItem('category_data', JSON.stringify(curData));
            localStorage.setItem('category_name', JSON.stringify(curData[0]?.category));         
        } 
    }, [curData]);
    const settings = {
        dots: true,
        infinite:true,
        speed: 500
    };
    return (
        <>
        {isLoading?<div className="loader-container">
      <div className="lds" ></div>
      </div>:
        <div className="container m-w">
            {allGamesJson &&
              <GameCategorySlider
                settings={{
                  ...settings,
                  dots: false,
                  arrows: false,
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  centerPadding: "10%",
                  swipeToSlide: true,
                }}
                data={allGamesJson}
              />
            }
            <div className="columns is-multiline is-mobile common is-gapless">
                <div className="column is-full v-center">
                    {/* <h6 className="title is-6 has-text-white mb-0 line"> {capatalize(categoryName)}</h6> */}
                </div>
                <div className="column is-full game-container">
                    {
                        curData &&  curData.length>0 && curData.map((item, i) => (
                            <GameCard key={item.id} data={item} play_text={play}/>
                        ))
                    }
                </div>
            </div>
        </div>}
        </>
    )
}
export default Category